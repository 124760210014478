import React, {useState} from 'react'
import { HeroContainer, HeroBG, VideoBg,
  HeroContent, HeroH1, HeroP, HeroBtnWrapper,
  ArrowForward, ArrowRight } from './HeroElements';
import {Button} from '../ButtonElement';
import Video from '../../videos/HeroBG.mp4';

const HeroSection = () => {
  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(!hover)
  }
  return (
    <HeroContainer>
      <HeroBG>
        <VideoBg autoPlay loop muted src={Video} type='video/mp4' /> x
      </HeroBG>
      <HeroContent>
        <HeroH1>Two Generations of Excellence in Resin Processing.</HeroH1>
        <HeroP>
            From fiberglass boat builders, to spa manufacturers, we've Supplyed quality resin storage
            equipment for over 50 years.
        </HeroP>
        <HeroBtnWrapper>
          <Button to="Contact" onMouseEnter={onHover} onMouseLeave={onHover}
          primary="true"
          dark="true">
            Contact us today {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection
