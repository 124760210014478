export const homeObjOne = {
    id:'BRS',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Bulk Resin Storage',
    headline:'Industry leading liquid resin handling systems and processing equipment.',
    description:'We provide bulk resin storage tanks, process chillers, heat exchangers, vat mixers,resin pumping units, tank farms, process heaters, process piping, drum mixers, and filtration systems which will carry your buisness forward while maintaing a safe and enviromentally friendly work enviroment',
    buttonLabel: 'Contact Us',
    imgStart: true,
    img: require('../../images/jpg-1.jpg'),
    alt: 'Resin Tank',
    dark: true,
    primary: true,
    darkText: false,
    hrefLink: 'https://www.google.com',
    displayButton:false
};

export const homeObjTwo = {
    id:'PE',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Processing Equipment',
    headline:'Custom processing equipment to fit your needs.',
    description:'NJR Industries has developed custom resin mixing, injection and casting systems for a variety of industries. We can design and fabricate resin handling equipment which will meet the strictest specifications for manufacturing your products.',
    buttonLabel: 'Learn more',
    imgStart: false,
    img: require('../../images/jpg-2.jpg'),
    alt: 'Process Equipment',
    dark: false,
    primary: false,
    darkText: true,
    hrefLink: 'https://www.google.com',
    displayButton:false
};

export const homeObjThree = {
    id:'CS',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Control Systems',
    headline:'Controls engineered to your needs.',
    description:'From a single pumping unit to a full motor control center, our custom control centers give you the control and automation you need. We also provide full technical support of all our systems, from diagnostic troubleshooting to on-site consulation and training.',
    buttonLabel: 'Contact Us',
    imgStart: true,
    img: require('../../images/jpg-3.jpg'),
    alt: 'Resin Tank',
    dark: true,
    primary: true,
    darkText: false,    
    hrefLink: '/',
    displayButton:false
};

export const homeObjFour = {
    id:'CIPP',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Cured-In-Place Pipe',
    headline:'The gold standard for wet out facilitites.',
    description:'NJR equipment and instrumentation spans the CIPP process from small in-plant mixers to full wet out facilities. Portable units include water heaters, portable mixers, inversion scaffolding, water pumping units and wet out conveyors for all diameter tubes.',
    buttonLabel: 'Watch Here',
    imgStart: false,
    img: require('../../images/jpg-4.jpg'),
    alt: 'Resin Tank',
    dark: false,
    primary: false,
    darkText: true,
    hrefLink: 'https://youtu.be/4wNxRIyRgOs',
    displayButton:true
};