import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper,
     SidebarMenu, SideBtnWrap, SidebarRoute, SidebarLink } from './SidebarElements';
import { animateScroll as scroll } from 'react-scroll';


const Sidebar = ({isOpen, toggle}) => {
  const toggleContact = () =>{
    scroll.scrollToBottom();
  }
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle} >
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
            <SidebarLink to="BRS" onClick={toggle}>
                Bulk Resin Storage
            </SidebarLink>
            <SidebarLink to="PE" onClick={toggle}>
                Processing Equipment
            </SidebarLink>
            <SidebarLink to="CS" onClick={toggle}>
                Control Systems
            </SidebarLink>
            <SidebarLink to="CIPP" onClick={toggle}>
                Cured-In-Place Pipe
            </SidebarLink>
        </SidebarMenu>
        <SideBtnWrap>
            <SidebarRoute onClick={toggleContact}>Contact Us</SidebarRoute>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
