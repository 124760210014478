import React, { useEffect, useState } from 'react'
import {FaBars} from 'react-icons/fa';
import {Nav, NavbarContainer, NavLogo,
     MobileIcon, NavMenu, NavItem, NavLinks, NavBtn, NavBtnLink} from './NavbarElements';
import { animateScroll as scroll } from 'react-scroll';

const Navbar = ({ toggle }) => {
    const [scrollNav, setScrollNav] = useState(false)
    
    const changeNav = () => {
        if(window.scrollY >= 80){
            setScrollNav(true);
        }
        else{
            setScrollNav(false);
        }
    };

    useEffect(()=>{
        window.addEventListener('scroll', changeNav)
    }, []);
    const toggleHome = () => {
        scroll.scrollToTop();
    }
    const toggleContact = () =>{
        scroll.scrollToBottom();
    }
    
    return(
        <>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavLogo to='/' onClick={toggleHome}>
                        NJR Industries
                    </NavLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                        <NavLinks 
                        to="BRS"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                        >Bulk Resin Storage</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks 
                            to="PE"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-80}
                            >Processing Equipment</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks 
                            to="CS"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={0}
                            >Control Systems</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks 
                            to="CIPP"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-80}
                            >Cured-In-Place Pipe</NavLinks>
                        </NavItem>
                    </NavMenu>
                    <NavBtn>
                        <NavBtnLink onClick={toggleContact}>Contact Us</NavBtnLink>
                    </NavBtn>
                </NavbarContainer>
            </Nav>
        </>
  );
};
export default Navbar;
