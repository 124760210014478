import React from 'react';
import { Button } from '../ButtonElement';
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2,
 TextWrapper, Heading, Subtitle, BtnWrap, ImgWrap, Img, TopLine, BtnLink} from './InfoElements';
const InfoSection = ({lightBg, id, imgStart, topLine, lightText,
     headline, darkText, description, img, alt, buttonLabel, primary, dark,
      dark2, hrefLink, displayButton}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
            <InfoRow imgStart={imgStart}>
                <Column1>
                <TextWrapper>
                    <TopLine>{topLine}</TopLine>
                    <Heading lightText={lightText}>{headline}</Heading>
                    <Subtitle darkText={darkText}>{description}</Subtitle>
                    <BtnLink href={hrefLink} target="_blank">
                    <BtnWrap>
                        <Button
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                        primary={primary ? 1 : 0}
                        dark={dark ? 1 : 0}
                        dark2={dark2 ? 1 : 0}
                        displayButton={displayButton ? 1 : 0}
                        >
                        {buttonLabel}
                        </Button>
                    </BtnWrap>
                    </BtnLink>
                </TextWrapper>
                </Column1>
                <Column2>
                    <ImgWrap>
                    <Img src={img} alt={alt} />
                    </ImgWrap>
                </Column2>
            </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default InfoSection
