import React from 'react';
import { ContactCotainer,
  ContactContent,
  ContactH1,
  ContactCard,
  ContactIcon,
  ContactH2,
  ContactP
} from './ContactElements';
import Icon1 from '../../images/Icon1.svg'
import Icon2 from '../../images/Icon2.svg'
import Icon3 from '../../images/Icon3.svg'

const Contact = () => {
  return (
    <>
      <ContactCotainer id="contact">
        <ContactH1>Contact us for an estimate today.</ContactH1>
        <ContactContent>
          <ContactCard>
            <ContactIcon src={Icon1} />
            <ContactH2>Adresss</ContactH2>
            <ContactP>1300 Walter Smith Road Mobile,Alabama</ContactP>
          </ContactCard>
          <ContactCard>
            <ContactIcon src={Icon2} />
            <ContactH2>Phone </ContactH2>
            <ContactP>(251)-633-0337</ContactP>
          </ContactCard>
          <ContactCard>
            <ContactIcon src={Icon3} />
            <ContactH2>E-mail</ContactH2>
            <ContactP>Eddie@NJRIND.com</ContactP>
          </ContactCard>
        </ContactContent>
      </ContactCotainer>
    </>
  )
}

export default Contact