import React from 'react'
import {FaFacebook, FaLinkedin} from 'react-icons/fa'
import { FooterContainer,
        FooterWrap,
        FooterLinksContainer,
        FooterLinksWrapper,
        FooterLinkItems,
        FooterLinkTitle,
        FooterLink,
        SocialMedia,
        SocialMediaWrap,
        SocialLogo,
        WebsiteRights,
        SocialIcons,
        SocialIconLink,
}from './footerElements'
const Footer = () => {
  return (
    <FooterContainer>
        <FooterWrap>
            <FooterLinksContainer>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>About Us</FooterLinkTitle>
                        <FooterLink to="BRS">Bulk Resin Storage</FooterLink>
                        <FooterLink to="PE">Processing Equipment</FooterLink>
                        <FooterLink to="CS">Control Systems</FooterLink>
                        <FooterLink to="CIPP">Cured-In-Place Pipe</FooterLink>
                    </FooterLinkItems>
                </FooterLinksWrapper>
            </FooterLinksContainer>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to='/'>
                        NJR
                    </SocialLogo>
                    <SocialIcons>
                        <SocialIconLink href="https://www.facebook.com/NjrIndustries" target="_blank"
                        aria-label="Facebook">
                            <FaFacebook/>
                        </SocialIconLink>
                        <SocialIconLink href="https://www.linkedin.com/company/njr-industreis-inc-/" target="_blank"
                        aria-label="LinkedIn">
                            <FaLinkedin/>
                        </SocialIconLink>
                    </SocialIcons>
                    <WebsiteRights>NJR ©{new Date().getFullYear()} All rights reserved.</WebsiteRights>
                </SocialMediaWrap>
            </SocialMedia>

        </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
